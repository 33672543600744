import React from 'react';

import RDMD from '@ui/RDMD';

import { fieldProps } from '../Form/types';
import {
  getDefaultFormState,
  guessType,
  hasSchemaType,
  isPolymorphic,
  retrieveSchema,
  shouldAlwaysUseDefaults,
} from '../Form/utils';
import classes from '../style.module.scss';

// Useful for debugging mutlischema striping. See custom templates for the other debug flag.
const depthDebug = false;

/**
 * Navigates a schema, trying to find the discriminator details
 */
function findDiscriminatorDetails(schema) {
  if (schema.discriminator) {
    return schema.discriminator;
  }

  // Sometimes discriminators are within each of the oneOf schemas, and not top level.
  if (schema?.oneOf) {
    // If the oneOf schemas has a discriminator, return that
    if (schema.oneOf?.[0]?.discriminator) {
      return schema?.oneOf?.[0]?.discriminator;
      // If the oneOf schema has an allOf for polymorphism, check that too for a discriminator
    } else if (schema.oneOf?.[0]?.allOf?.[0]?.discriminator) {
      return schema.oneOf[0].allOf[0].discriminator;
    }
  }

  // Sometimes discriminators are within each of the anyOf schemas, and not top level.
  if (schema?.anyOf) {
    // If the anyOf schemas has a discriminator, return that
    if (schema.anyOf?.[0]?.discriminator) {
      return schema?.anyOf?.[0]?.discriminator;
      // If the anyOf schema has an allOf for polymorphism, check that too for a discriminator
    } else if (schema.anyOf?.[0]?.allOf?.[0]?.discriminator) {
      return schema.anyOf[0].allOf[0].discriminator;
    }
  }

  // There is no allOf top level check because that implies there's no option, it's all the sub schemas.
  // Yes the subschema might have a discriminator, but multischemafield handles that with a nested multischemafield

  return false;
}

/**
 * Find the property that the discriminator is referencing.
 */
function findDiscriminatorField(schema, options, property) {
  if (schema.properties?.[property]) {
    return schema.properties[property];
  }

  // I need to return early, so I can't .forEach, wish I could use a for-of loop!
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (option.properties?.[property]) {
      return option.properties[property];
    }

    if (option.allOf?.[0]?.properties?.[property]) {
      return option.allOf[0].properties[property];
    }
  }

  return null;
}

/**
 * Creates a discriminator:schema mapping of all valid schemas for this discriminator
 */
function getDiscriminatorOptions(discriminatorSchemas) {
  const schemaOptions = {};

  discriminatorSchemas.forEach(schema => {
    schemaOptions[schema['x-readme-ref-name']] = schema;
  });

  return schemaOptions;
}

/**
 * This function essentially dereferences the mapping component so that we can render options based on the mapping
 * selection. It returns a discriminator:schema mapping identical to `getDiscriminatorOptions` but with the mapping
 * applied, which is particularly important if you have different mappings pointing to the same `$ref` (this is a valid
 * use of OpenAPI schemas).
 *
 * e.g.
 *  "credit": "#/components/schemas/CreditCard",
 *  "debit": "#/components/schemas/CreditCard",
 */
function getMappingOptions(mapping, discriminatorSchemas) {
  const schemaOptions = {};
  const discriminatorOptions = getDiscriminatorOptions(discriminatorSchemas);

  Object.keys(mapping).forEach(mappingKey => {
    // Discriminators can be either a `$ref` pointer or just the schema name.
    // https://github.com/OAI/OpenAPI-Specification/blob/main/versions/3.0.3.md#discriminatorObject
    if (mapping[mappingKey] in discriminatorOptions) {
      schemaOptions[mapping[mappingKey]] = discriminatorOptions[mapping[mappingKey]];
    } else {
      const match = mapping[mappingKey].match(/#\/components\/schemas\/([^/]+)/);
      if (match) {
        Object.keys(discriminatorOptions).forEach(discriminatorKey => {
          // If the mapping name from the `$ref` matches the unmapped discriminator value, record the mapping instead.
          if (match[1] === discriminatorKey) {
            schemaOptions[mappingKey] = discriminatorOptions[discriminatorKey];
          }
        });
      }
    }
  });

  return schemaOptions;
}

class AccordionMultiSchemaField extends React.Component {
  constructor(props) {
    super(props);
    const { options, schema } = props;
    const discriminatorSchema = findDiscriminatorDetails(schema);

    this.state = {
      expandedOption: false,
      discriminatorSchema,
      discriminatorFieldSchema: discriminatorSchema
        ? findDiscriminatorField(schema, options, discriminatorSchema.propertyName)
        : null,
    };
  }

  render() {
    const {
      baseType,
      disabled,
      formContext,
      formData,
      idPrefix,
      idSchema,
      onChange,
      onBlur,
      onFocus,
      options,
      registry,
      uiSchema,
      depth,
    } = this.props;

    const { rootSchema } = registry;
    const SchemaField = registry.fields.SchemaField;
    const optionComponents = [];
    const { expandedOption, discriminatorSchema } = this.state;

    // TODO: Memoing this and all the discriminator logic might speed up rendering.
    //    We would only need to recompute if formData changes, because nothing else will change.
    //    We can't do it in the constructor because formdata will change post construction.
    let renderableOptions = options.map(option => retrieveSchema(option, rootSchema, formData));

    const onClickOption = (index, state) => {
      // If the new option is of `type: object` and the current formdata is an object, discard form data that was
      // added using the previous option.
      let newFormData;
      if (
        guessType(formData) === 'object' &&
        (hasSchemaType(renderableOptions[index], 'object') || renderableOptions[index].properties)
      ) {
        newFormData = { ...formData };

        const optionsToDiscard = renderableOptions.slice();
        optionsToDiscard.splice(index, 1);

        // Discard any data added using other options
        optionsToDiscard.forEach(opt => {
          if (opt.properties) {
            Object.keys(opt.properties).forEach(key => {
              if (key in newFormData) {
                delete newFormData[key];
              }
            });
          }
        });
      }

      onChange(
        getDefaultFormState(renderableOptions[index], {
          alwaysUseDefaults: shouldAlwaysUseDefaults(formContext || {}),
          formData: newFormData,
          rootSchema,
        }),
      );

      this.setState({ expandedOption: state ? index : false });
    };

    let discriminators = null;
    let labelMapping = [];

    if (discriminatorSchema) {
      let discriminatorOptions = null;

      if (discriminatorSchema.mapping) {
        discriminatorOptions = getMappingOptions(discriminatorSchema.mapping, renderableOptions);
      } else {
        // If there isn't a mapping, the provided options define the dropdown list.
        discriminatorOptions = getDiscriminatorOptions(renderableOptions);
      }

      // Override the renderableOptions value wiht the new discriminator mapped values
      renderableOptions = Object.values(discriminatorOptions);
      discriminators = Object.keys(discriminatorOptions);

      const titleCounts = renderableOptions.reduce((prev, cur) => {
        if (cur.title) {
          prev[cur.title] = (prev[cur.title] || 0) + 1;
        }

        return prev;
      }, {});

      labelMapping = renderableOptions.map((option, index) => {
        return option.title
          ? `${option.title}${titleCounts[option.title] > 1 ? ` (${discriminators[index]})` : ''}`
          : discriminators[index];
      });
    }

    renderableOptions.forEach((option, index) => {
      // Because an option might sometimes be an `allOf` and one of its available options might have a `title` we should
      // retrieve the schema here because `retrieveSchema` will merge it into something that we can easily pluck a title
      // out of.
      const optionSchema = retrieveSchema(option, rootSchema, formData);

      // Prefer the user defined title, else fall back to the reference key, else fall back to a generic Option {number} value.
      const label =
        labelMapping[index] ||
        optionSchema.title ||
        optionSchema['x-readme-ref-name'] ||
        (Array.isArray(optionSchema.type) ? optionSchema.type.join(' | ') : optionSchema.type) ||
        `Option ${index + 1}`;

      const SchemaContents = schemaOption => {
        const field = newDepth => {
          return (
            <SchemaField
              key={`multi-option-accordion-${idSchema.$id}-${index}`}
              depth={newDepth}
              disabled={disabled}
              discriminator={!discriminatorSchema ? null : { ...discriminatorSchema, value: discriminators[index] }}
              formData={formData}
              idPrefix={idPrefix}
              idSchema={idSchema}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              registry={registry}
              // If the subschema doesn't declare a type, infer the type from the
              // parent schema
              schema={schemaOption.type ? schemaOption : { ...schemaOption, type: baseType }}
              uiSchema={uiSchema}
            />
          );
        };

        // This is a mess, and indicitive of the mess that is this librarys approach at nesting.
        // Polymorphic options and array options don't need the section wrapper. Everything else does.
        // Ideally everything would manage its own section, but it doesnt. so we're here.
        // As for the depth, we want to increment the wrapper here, and 2x increment the schemafield

        // Individual items tend to increment on their way down, but polymorhpism doesn't
        //  so we adjust the polymorphism here
        if (isPolymorphic(optionSchema) || hasSchemaType(optionSchema, 'array')) {
          return field(depth + 1);
        }

        // Everything else needs a section wrapper
        return (
          <section
            className={`${classes['Param-expand']} ${
              expandedOption === index ? classes['Param-expand_expanded'] : ''
            } ${Math.abs(depth + 1) % 2 === 1 ? 'odd' : 'even'}`}
            data-depth={depthDebug ? depth : undefined}
          >
            {field(depth + 1)}
          </section>
        );
      };

      optionComponents.push(
        <section
          key={`multi-option-accordion-wrapper-${idSchema.$id}-${index}`}
          className={`${classes['Param-expand']} ${expandedOption === index ? classes['Param-expand_expanded'] : ''} ${
            Math.abs(depth) % 2 === 1 ? 'odd' : 'even'
          } ${depthDebug ? `depth-${depth}` : ''}`}
        >
          <div className={'panel panel-default panel-body'}>
            <div
              className={`${classes['AccordionMultiSchema-option']} ${
                expandedOption === index ? classes['AccordionMultiSchema-option_expanded'] : ''
              }`}
            >
              <button
                className={`Flex ${classes['Param-expand-button']} ${
                  expandedOption === index ? classes['Param-expand-button_expanded'] : ''
                } ${classes['AccordionMultiSchema-header']}`}
                onClick={() => onClickOption(index, expandedOption !== index)}
                type="button"
              >
                <div className={`${classes['AccordionMultiSchema-details']} ${classes['AccordionMultiSchema-label']}`}>
                  {label}
                </div>
                <i
                  className={`${expandedOption === index ? 'icon-chevron-down' : 'icon-chevron-rightward'} ${
                    classes['Param-expand-button-icon']
                  }`}
                />
              </button>
              {expandedOption === index && (
                <div className={`${classes['AccordionMultiSchema-details']}`}>
                  <RDMD className={`${classes['AccordionMultiSchema-description']}`}>
                    {optionSchema.description ? optionSchema.description : ''}
                  </RDMD>
                  {SchemaContents(optionSchema)}
                </div>
              )}
            </div>
          </div>
        </section>,
      );
    });

    return optionComponents;
  }
}

AccordionMultiSchemaField.propTypes = fieldProps;

export default function createAccordionMultiSchemaField() {
  return AccordionMultiSchemaField;
}
